import React, { useRef } from 'react';
import { TemplateActions as Actions } from '@cvent/carina/components/Templates/TemplateActions';
import { ActionType } from '@cvent/carina/components/Templates/utils/helpers';
import { IllustrationErrorTypes as ErrorTypes } from '@cvent/carina/components/Templates/IllustrationTypes';
import { getIllustrationFromType } from '@cvent/carina/components/Templates/getIllustrationFromType';
import { IllustrationContent } from '@cvent/carina/components/Templates/IllustrationContent';
import { IllustrationNotice } from '@cvent/carina/components/Templates/IllustrationNotice';
import { IllustrationHeader } from '@cvent/carina/components/Templates/IllustrationHeader';
import { Body } from '@cvent/carina/components/Templates/Body';
import { Main } from '@cvent/carina/components/Templates/Main';
import { ContentArea } from '@cvent/carina/components/Templates/ContentArea';
import { Page } from '@cvent/carina/components/Templates/Page';
import { NextPage } from 'next/types';
import Router from 'next/router';
import { useTranslate } from 'nucleus-text';
import { IllustrationProps } from '@cvent/carina/components/Illustrations';

const actions = (
  returnUrl: React.MutableRefObject<string | null>,
  translate: (key: string) => string
): ActionType[] => [
  {
    value: translate('CustomApps-ErrorPage-GoBack-ButtonLabel'),
    onClick: (): void => {
      if (returnUrl.current) {
        Router.push(returnUrl.current);
      } else {
        Router.back();
      }
    }
  }
];

export const NotFound: NextPage = () => {
  const { translate } = useTranslate();
  const returnUrl: React.MutableRefObject<string | null> = useRef(null);
  return (
    <Page>
      <Main>
        <Body>
          <ContentArea>
            <IllustrationNotice
              illustration={
                getIllustrationFromType(ErrorTypes.ERROR_404) as React.ComponentType<IllustrationProps> | undefined
              }
              title=""
            >
              <IllustrationHeader>{translate('CustomApps-ErrorPage-Title')}</IllustrationHeader>
              <IllustrationContent>{translate('CustomApps-ErrorPage-Content-404')}</IllustrationContent>
              <Actions actions={actions(returnUrl, translate)} position="center" />
            </IllustrationNotice>
          </ContentArea>
        </Body>
      </Main>
    </Page>
  );
};

export default NotFound;
